/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { Divider } from '@material-ui/core'
import SingleForumCategory from './SingleForumCategory'

interface Category {
  id?: number | null
  name?: string
  color?: string
  description: string
  updated_at?: string
  slug?: string
}

const initialCategoryData = {
  id: null,
  name: '',
  color: '',
  description: '',
  updated_at: '',
}

const NewForumCategory = () => {
  return (
    <div className='login-history'>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>Create New Category</h2>
      </div>

      <Divider />

      <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
        <SingleForumCategory newCategory data={initialCategoryData} />
      </div>
    </div>
  )
}

export default NewForumCategory
