import React, { memo } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { useHistory } from 'react-router-dom'
import subDays from 'date-fns/subDays'
import PropTypes from 'prop-types'
import { useGlobalContext } from '../../../context/globalContext'

/**
 * Chart
 * @param {object} data - chart data
 * @param {string|number} tab - current chart tab
 */

const Chart = ({ data, tab }) => {
  const history = useHistory()
  const { setGlobalSearchFilters } = useGlobalContext()
  const options = {
    // responsive:true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (context) => {
            if (context.dataIndex === 0) {
              return 'Very low'
            } else if (context.dataIndex === 1) {
              return 'Low'
            } else if (context.dataIndex === 2) {
              return 'Medium'
            } else if (context.dataIndex === 3) {
              return 'High'
            } else if (context.dataIndex === 4) {
              return 'Very High'
            }
          },
        },
        // titleColor: '#000'
        // enabled: false,
      },
    },
  }

  return (
    <>
      <div className='chart-container__canvas' style={{ width: '100%' }}>
        <Doughnut
          width={300}
          height={300}
          type='doughnut'
          onMouseOver={(event) => (event.target.style.cursor = 'pointer')}
          data={data}
          options={options}
          getElementAtEvent={(dataset) => {
            if (dataset.length > 0) {
              const index = dataset[0].index
              const severity = index + 1 //index === 0 ? [3] : index === 1 ? [4] : [5]
              const filters = {
                fromDashboard: true,
                priority: [severity],
                currentTab: 'alerts',
              }

              if (tab !== 'all') {
                filters.dateRange = [subDays(new Date(), tab), new Date()]
              }
              setGlobalSearchFilters(filters)
              history.push('/app/search')
            }
          }}
        />
      </div>

      <div className='chart-container__label'>
        <div>
          <span style={{ fontSize: 12, color: '#6FFF54' }}>Very low</span>

          <span style={{ color: '#6FFF54' }}>{data.datasets[0].data[0]}%</span>
        </div>

        <div>
          <span style={{ fontSize: 12, color: '#FFF738' }}>Low</span>

          <span style={{ color: '#FFF738' }}>{data.datasets[0].data[1]}%</span>
        </div>

        <div>
          <span style={{ fontSize: 12, color: '#FFB83D' }}>Medium</span>

          <span style={{ color: '#FFB83D' }}>{data.datasets[0].data[2]}%</span>
        </div>

        <div>
          <span style={{ fontSize: 12, color: '#FF7F3D' }}>High</span>

          <span style={{ color: '#FF7F3D' }}>{data.datasets[0].data[3]}%</span>
        </div>

        <div>
          <span style={{ fontSize: 12, color: '#FF6F6A' }}>Very high</span>

          <span style={{ color: '#FF6F6A' }}>{data.datasets[0].data[4]}%</span>
        </div>
      </div>
    </>
  )
}

Chart.displayName = 'Chart'

/**
 * The properties.
 * @type {Object}
 */

Chart.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  tab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default memo(Chart)
