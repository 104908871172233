import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const getReportedResource = (token, type) =>
  client.get(apiUrl + `/api/moderation-reports/${type}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const deleteReportedResource = (token, id, type) =>
  client.remove(apiUrl + `/api/moderation-reports/${type}/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const reportedResourceStatus = (token, id, data, type) =>
  client.put(apiUrl + `/api/moderation-reports/${type}/${id}`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const reportedResourceThread = (token, id, type) =>
  client.get(apiUrl + `/api/moderation-reports/${type}/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
