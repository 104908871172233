import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import './WorkspaceContent.scss'
import Button from '@material-ui/core/Button'
import Alerts from '../Alerts'
import Dashboard from '../Dashboard'
import Profiles from '../Profiles'
import Reports from '../Reports'
import Scenarios from '../Scenarios'
import CreateIcon from '@material-ui/icons/Create'
import { InputAdornment, TextField } from '@material-ui/core'
import { useGlobalContext } from '../../context/globalContext'
import { isMobile } from 'react-device-detect'
import LinkAnalysis from '../LinkAnalysis'
import useNotification from '../../hooks/useNotification'
import { useUserContext } from '../../context/userContext'
import { useViewStateContext } from '../../context/viewStateContext'
import { useChat } from '../../context/chatContext'

const WorkspaceContent = ({
  configuration,
  dashboardData,
  setDashboardData,
}) => {
  const {
    setGlobalSearchFilters,
    isContentBeingEdited,
    setToastMessage,
    handleGlobalState,
  } = useGlobalContext()
  const {
    leftMenuVisible,
    user: {
      modules: { view_mode },
    },
  } = useUserContext()
  const { chatOpen } = useChat()
  const { workspaceIdCount, workspaces, setWorkspaces, modifyWorkspaceName } =
    useViewStateContext()

  const [currentModule, setCurrentModule] = useState('')
  const history = useHistory()
  const searchRef = useRef(null)
  const { warningToast, infoToast } = useNotification()

  useEffect(() => {
    const current = workspaces?.find((item) => item.active)

    if (current) {
      setCurrentModule(current)
    } else {
      const newWorkspace = {
        name: 'New workspace',
        id: workspaceIdCount + 1,
        state: '',
        active: true,
        filters: {},
        currentPiece: null,
        currentTab: 'content',
      }

      setWorkspaces([...workspaces, newWorkspace])
    }
  }, [workspaces])

  const handleSelectModule = (key, name) => {
    if (key !== 'create') {
      let newWorkspace = [...workspaces]

      const linkAnalysisPosition = newWorkspace.findIndex(
        (item) => item.name === 'Link Analysis',
      )

      if (linkAnalysisPosition > -1 && name === 'Link Analysis') {
        return warningToast({
          message:
            'A link analysis workspace is currently open. Please close it before opening a new one',
        })
      }

      newWorkspace.forEach((workspace) => {
        if (workspace.active) {
          workspace.state = key
          workspace.name = name
          workspace.isNew = !isMobile //for loading the first piece of content when opening a module
        }
      })

      //opening another of the same module resets current list to trigger new
      let moduleName = key !== 'malware-tools' ? key + 's' : 'malware-tools'
      handleGlobalState(moduleName, 'list', [])

      setWorkspaces(newWorkspace)
    } else {
      let newWorkspace = [...workspaces]

      newWorkspace.forEach((workspace) => {
        if (workspace.active) {
          workspace.state = key
        }
      })

      setWorkspaces(newWorkspace)
    }
  }

  const handleOpenCreate = () => {
    if (isContentBeingEdited) {
      setToastMessage(['warning', 'Content is currently being edited.'])

      setTimeout(() => setToastMessage(null), 3000)
    } else {
      setWorkspaces((currentState) => {
        const newState = currentState.filter(
          (workspace) => workspace.id !== currentModule.id,
        )

        return newState
      })
      history.push('/app/content-create')
    }
  }

  return (
    <div
      className={`workspace${
        chatOpen && currentModule?.state !== 'dashboard' ? ' chat-open' : ''
      }`}
      style={{ width: leftMenuVisible ? null : '100%' }}
    >
      {currentModule?.state === '' && (
        <div className='modules-container'>
          <form
            onSubmit={(event) => {
              event.preventDefault()
              setGlobalSearchFilters({
                fromDashboard: true,
                keywords: searchRef.current.value,
              })
              history.push('/app/search')
            }}
          >
            <TextField
              style={{ width: '40%', minWidth: 600 }}
              placeholder='Search all content'
              inputRef={searchRef}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <span
                      className='icon-search'
                      style={{ color: '#283143', fontSize: 30 }}
                    />
                  </InputAdornment>
                ),
              }}
              variant='standard'
            />
          </form>

          <div className='modules-container__modules'>
            {view_mode.map(
              (item) =>
                item.name !== 'Chat' && (
                  <Button
                    className='modules-container__select'
                    onClick={() => {
                      if (!item.has_access) {
                        infoToast({
                          message:
                            'You do not have access to this module or content. Please contact our sales team for more information.',
                          keepOpen: true,
                        })
                      } else {
                        const linkAnalysisAlreadyOpen = workspaces.find(
                          (workspace) => workspace.state === 'analysis',
                        )
                        if (
                          item?.slug === 'analysis' &&
                          linkAnalysisAlreadyOpen
                        ) {
                          warningToast({
                            message:
                              'A link analysis workspace is currently open. Please close it before opening a new one',
                          })
                        } else {
                          handleSelectModule(item.slug, item.name)
                        }
                      }
                    }}
                    key={item.slug}
                    disableRipple
                    size='small'
                    color='primary'
                  >
                    <div>
                      <span>{item.name}</span>

                      <span className={`icon icon-${item.slug}`} />
                    </div>
                  </Button>
                ),
            )}
          </div>

          <Button
            className='modules-container__create'
            size='small'
            disableRipple
            onClick={() => handleOpenCreate()}
            color='primary'
          >
            Create Content
            <CreateIcon />
          </Button>
        </div>
      )}

      {currentModule?.state === 'dashboard' && (
        <Dashboard
          configuration={configuration}
          dashboardData={dashboardData}
          setDashboardData={setDashboardData}
        />
      )}

      {currentModule?.state === 'alert' && (
        <Alerts
          currentModule={currentModule}
          configuration={configuration}
          modifyWorkspaceName={modifyWorkspaceName}
        />
      )}

      {currentModule?.state === 'threat-actor' && (
        <Profiles
          currentModule={currentModule}
          configuration={configuration?.profile}
          type={1}
          typeName='threat-actors'
          modifyWorkspaceName={modifyWorkspaceName}
        />
      )}

      {currentModule?.state === 'incident' && (
        <Profiles
          currentModule={currentModule}
          configuration={configuration?.profile}
          type={3}
          typeName='incidents'
          modifyWorkspaceName={modifyWorkspaceName}
        />
      )}

      {currentModule?.state === 'operation' && (
        <Profiles
          currentModule={currentModule}
          configuration={configuration?.profile}
          type={4}
          typeName='operations'
          modifyWorkspaceName={modifyWorkspaceName}
        />
      )}

      {currentModule?.state === 'malware-tools' && (
        <Profiles
          currentModule={currentModule}
          configuration={configuration?.profile}
          type={5}
          typeName='malware-tools'
          modifyWorkspaceName={modifyWorkspaceName}
        />
      )}

      {currentModule?.state === 'scenario' && (
        <Scenarios
          currentModule={currentModule}
          configuration={configuration?.scenario}
          modifyWorkspaceName={modifyWorkspaceName}
        />
      )}

      {currentModule?.state === 'report' && (
        <Reports
          currentModule={currentModule}
          configuration={configuration?.report?.types}
          modifyWorkspaceName={modifyWorkspaceName}
        />
      )}

      {currentModule?.state === 'analysis' && (
        <div>
          <LinkAnalysis view={currentModule?.currentTab === 'view'} />
        </div>
      )}
    </div>
  )
}

export default WorkspaceContent
