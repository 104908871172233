import { Button } from '@material-ui/core'

const OptionsMenu = ({ comment, handleChangeStatus, id, type }) => {
  return (
    <div style={{ display: 'flex' }}>
      {(comment.status !== 'Comment shown' ||
        comment.status !== 'Content shown') && (
        <Button
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            color: '#000',
            textTransform: 'capitalize',
            borderRadius: 12,
            padding: '3px 14px',
          }}
          onClick={() =>
            handleChangeStatus(
              id,
              type === 'comments' ? 'Comment shown' : 'Content shown',
              type,
            )
          }
        >
          Restore
        </Button>
      )}

      {(comment.status !== 'Comment hidden' ||
        comment.status !== 'Content hidden') && (
        <Button
          style={{
            backgroundColor: '#ff0033',
            color: '#fff',
            textTransform: 'capitalize',
            borderRadius: 12,
            padding: '3px 14px',
          }}
          onClick={() =>
            handleChangeStatus(
              id,
              type === 'comments' ? 'Comment hidden' : 'Content hidden',
              type,
            )
          }
        >
          Delete
        </Button>
      )}
    </div>
  )
}

export default OptionsMenu
