import React, { Fragment, useState } from 'react'
import './AdminContentExports.scss'
import { Button, Divider } from '@material-ui/core'
import { Save } from '@material-ui/icons'
import { apiUrl } from '../../shared/config'

const AdminContentExports = () => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [hoverItem, setHoverItem] = useState(null)
  const modules = [
    { name: 'Threat Alerts', slug: 'alert' },
    { name: 'Profiles', slug: 'profile' },
    { name: 'Scenarios', slug: 'scenario' },
    { name: 'Reports', slug: 'report' },
  ]

  const exportContentList = async (module) => {
    window.open(
      `${apiUrl}/api/content/download?module=${module}&access_token=${token()}`,
      '_blank',
    )
  }

  return (
    <div className='content-exports'>
      <div className='main__inner'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h3>Content Exports</h3>

          <h4>
            Please note that the export only includes content created in the
            last 6 months
          </h4>
        </div>

        <Divider style={{ margin: '20px 0' }} />

        <div className='content-exports-container'>
          {modules.map(({ slug, name }) => (
            <Fragment key={slug}>
              <div
                className='content-exports-wrapper'
                onMouseEnter={() => setHoverItem(slug)}
                onMouseLeave={() => setHoverItem(null)}
              >
                <span
                  style={{ padding: '7px 0', fontWeight: 600, fontSize: 18 }}
                >
                  {name}
                </span>

                {hoverItem === slug && (
                  <Button
                    onClick={() => exportContentList(slug)}
                    startIcon={<Save />}
                    className='edit'
                  >
                    Download Content List
                  </Button>
                )}
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AdminContentExports
