import client from '../../../http/client'

import { apiUrl } from '../../../../config/index'

export const singleTag = (token, tagSlug, id) =>
  client.get(apiUrl + `/api/${tagSlug}/${id}/edit`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const addTagCategory = (token, data) =>
  client.post(apiUrl + `/api/tag-categories`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const editTagCategory = (token, id, data) =>
  client.put(apiUrl + `/api/tag-categories/${id}`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const addTag = (token, data) =>
  client.post(apiUrl + `/api/tags`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const editTag = (token, id, data) =>
  client.put(apiUrl + `/api/tags/${id}`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const categoriesOptions = (token) =>
  client.get(apiUrl + '/api/tag-categories?nopagination', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const stixOptions = (token) =>
  client.get(apiUrl + '/api/stix/vocabulary', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const organisationsList = (token) =>
  client.get(
    apiUrl + `/api/apps/list-with-organisations?nopagination&with_users=1`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const userPermissions = (token, orgId, userId) =>
  client.get(apiUrl + `/api/modules/content/organisations/${orgId}/${userId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
