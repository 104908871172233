/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState, useEffect } from 'react'
import './Signings.scss'
import { getLoginHistory } from '../../../../shared/services/api/logs/logs'
import Pagination from '@material-ui/lab/Pagination'
import {
  Button,
  Divider,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Tooltip,
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Spinner from '../../../../shared/Spinner'
import { formatGlobalSearch } from '../../../../shared/helpers/formatDate'
import { apiUrl } from '../../../../shared/config'
import { useAdmin } from '../../../../context/adminContext'
import useNotification from '../../../../hooks/useNotification'
import { Info } from '@material-ui/icons'

const columnData = [
  { id: 'Email' },
  { id: 'Signed In' },
  { id: 'Organisation' },
  { id: 'Group' },
  { id: 'Communities' },
  { id: 'Date' },
  { id: 'Time' },
]

const Signings = ({}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [isLoading, setIsLoading] = useState(false)
  const {
    adminState: { signings },
    setAdminState,
  } = useAdmin()
  const [page, setPage] = useState(
    +Object.fromEntries(new URLSearchParams(signings?.previousQuery))?.page ||
      1,
  )
  const [keyword, setKeyword] = useState(
    Object.fromEntries(new URLSearchParams(signings?.previousQuery))?.keyword ||
      '',
  )
  const [columns, setColumns] = useState(columnData)
  const [selectedDateFrom, setSelectedDateFrom] = useState(new Date())
  const [selectedDateTo, setSelectedDateTo] = useState(new Date())
  const [menuOpen, setMenuOpen] = useState(false)
  const { warningToast } = useNotification()

  const exportLogs = async () => {
    const dateFrom = formatGlobalSearch(selectedDateFrom)
    const dateTo = formatGlobalSearch(selectedDateTo)
    window.location.href = `${apiUrl}/logs/signings/download?access_token=${token()}&dateFrom=${dateFrom}&dateTo=${dateTo}`
  }

  const fetchLogs = async (forceReload) => {
    try {
      const query = new URLSearchParams({
        keyword: keyword,
        page: page,
      }).toString()

      if (signings?.previousQuery === query && !forceReload) return false
      setIsLoading(true)
      const response = await getLoginHistory(token(), query)

      if (response?.list?.data?.length === 0) {
        warningToast({ message: 'No results found.' })
      }

      response.list.previousQuery = query
      setAdminState((currentState) => ({
        ...currentState,
        signings: response.list,
      }))
      setIsLoading(false)
    } catch {}
  }

  useEffect(() => {
    fetchLogs()
  }, [page])

  const handleSelectedDateFrom = (value) => {
    setSelectedDateFrom(value)
    value > selectedDateTo && setSelectedDateTo(value)
  }

  const handleClose = () => setTimeout(() => setMenuOpen(false), 25)
  const numberOfGroups = (user, isCommunity) =>
    user?.origin_organisation?.assigned_in_groups?.filter((group) =>
      isCommunity ? group.is_community_group : !group.is_community_group,
    )

  return (
    <div className='signings'>
      <div className='signings-header'>
        <h2>Login History</h2>
      </div>

      <Divider />

      <div className='signings-header__datepicker'>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div>
            <h5>Search</h5>

            <form
              onSubmit={(event) => {
                event.preventDefault()
                setPage(1)
                const ignore = fetchLogs(true)
              }}
            >
              <OutlinedInput
                variant='outlined'
                placeholder='Search'
                value={keyword}
                onChange={(event) => setKeyword(event.target.value)}
                startAdornment={
                  <InputAdornment position='start'>
                    <span style={{ fontSize: 26 }} className='icon-search' />
                  </InputAdornment>
                }
              />
            </form>
          </div>

          <div>
            <div>
              <h5>Date from</h5>

              <DatePicker
                disableToolbar
                autoOk
                variant='inline'
                inputVariant='outlined'
                format='dd/MM/yyyy'
                value={selectedDateFrom}
                onChange={(value) => handleSelectedDateFrom(value)}
                InputProps={{ endAdornment: <span className='icon-down' /> }}
              />
            </div>

            <div>
              <h5>Date to</h5>

              <DatePicker
                disableToolbar
                autoOk
                InputProps={{ endAdornment: <span className='icon-down' /> }}
                variant='inline'
                inputVariant='outlined'
                format='dd/MM/yyyy'
                minDate={selectedDateFrom}
                value={selectedDateTo}
                onChange={setSelectedDateTo}
              />
            </div>

            <Button onClick={() => exportLogs()} size='large'>
              Export
            </Button>
          </div>
        </MuiPickersUtilsProvider>
      </div>

      {isLoading ? (
        <Spinner centerHorizontally padding={20} size={30} />
      ) : (
        <div>
          <table>
            <thead>
              <tr>
                {columns.map((item) => (
                  <th key={item.id}>{item.id}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {signings?.data?.map(
                ({
                  id,
                  user,
                  email,
                  error,
                  is_signed_in,
                  created_at_date,
                  created_at_time,
                }) => (
                  <tr key={id}>
                    <td>
                      {user?.email || (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          User deleted
                          <Tooltip placement='top' title={email} arrow>
                            <Info style={{ marginLeft: 6, fontSize: 17.5 }} />
                          </Tooltip>
                        </span>
                      )}
                    </td>

                    <td>
                      <Tooltip title={!is_signed_in ? error : ''}>
                        <span
                          className={
                            is_signed_in
                              ? 'icon-circle-check'
                              : 'icon-circle-close'
                          }
                          style={{
                            color: is_signed_in ? '#3aaa35' : '#d70000',
                            fontSize: 30,
                          }}
                        />
                      </Tooltip>
                    </td>

                    <td>
                      <span style={{ fontSize: 12, fontWeight: 600 }}>
                        {user?.origin_organisation?.name || '-'}
                      </span>
                    </td>

                    <td>
                      {numberOfGroups(user, false)?.length <= 3 ? (
                        <span style={{ fontSize: 12, fontWeight: 600 }}>
                          {numberOfGroups(user, false)
                            .map((item) => item.name)
                            .join(', ')}
                        </span>
                      ) : numberOfGroups(user, false)?.length > 3 ? (
                        <Button
                          style={{
                            textTransform: 'capitalize',
                            padding: '6px 16px',
                            borderRadius: 12,
                            fontSize: 12,
                            fontWeight: 600,
                          }}
                          onMouseEnter={(event) =>
                            setMenuOpen([event.currentTarget, id, false])
                          }
                          endIcon={<span className='icon-down' />}
                        >
                          Groups
                        </Button>
                      ) : (
                        '-'
                      )}
                    </td>

                    <td>
                      {numberOfGroups(user, true)?.length <= 3 ? (
                        <span style={{ fontSize: 12, fontWeight: 600 }}>
                          {numberOfGroups(user, true)
                            .map((item) => item.name)
                            .join(', ')}
                        </span>
                      ) : numberOfGroups(user, true)?.length > 3 ? (
                        <Button
                          style={{
                            textTransform: 'capitalize',
                            padding: '6px 16px',
                            borderRadius: 12,
                          }}
                          onMouseEnter={(event) =>
                            setMenuOpen([event.currentTarget, id, false])
                          }
                          endIcon={<span className='icon-down' />}
                        >
                          Communities
                        </Button>
                      ) : (
                        '-'
                      )}
                    </td>

                    <td>{created_at_date}</td>

                    <td>{created_at_time}</td>
                  </tr>
                ),
              )}
            </tbody>
          </table>

          {menuOpen !== false && (
            <Menu
              anchorEl={menuOpen !== false && menuOpen[0]}
              open={menuOpen !== false}
              MenuListProps={{ onMouseLeave: handleClose }}
            >
              {signings?.data
                ?.find((log) => log.id === menuOpen[1])
                ?.user?.origin_organisation?.assigned_in_groups?.filter(
                  (group) => group.is_community_group === menuOpen[2],
                )
                .map((group) => (
                  <MenuItem
                    disableRipple
                    style={{ padding: '4px 12px', fontSize: 12 }}
                    key={group.pivot.id}
                  >
                    {group.name}
                  </MenuItem>
                ))}
            </Menu>
          )}

          <Pagination
            onChange={(event, value) => setPage(value)}
            page={page}
            count={signings?.last_page}
            shape='rounded'
            hideNextButton
            hidePrevButton
            color='primary'
          />
        </div>
      )}
    </div>
  )
}

export default Signings
