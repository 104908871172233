/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, MenuItem, Select } from '@material-ui/core'
import Spinner from '../../../../shared/Spinner'
import { useHistory, useParams } from 'react-router-dom'
import { Chip, ListSubheader, TextField } from '@mui/material'
import {
  singleTag,
  addTag,
  editTag,
  categoriesOptions,
  stixOptions,
} from '../../../../shared/services/api/admin/settings/tags'
import { Autocomplete } from '@material-ui/lab'
import './EditTag.scss'
import debounce from 'lodash/debounce'
import { cancelRequests } from '../../../../shared/services/http/client'
import { tagsList } from '../../../../shared/services/api/admin/settings/sectors'
import useNotification from '../../../../hooks/useNotification'

const EditTag = ({}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const initialData = {
    name: '',
    categories: [],
    tags: [],
    tagsData: [],
    stix_type: '',
  }
  const history = useHistory()
  const { id } = useParams()
  const isCreateNew = id === 'new'
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState(isCreateNew ? initialData : {})
  const [tagsOptions, setTagsOptions] = useState({})
  const [inputValue, setInputValue] = useState('')
  const [stixMenu, setStixMenu] = useState(null)
  const { successToast, errorToast } = useNotification()

  const getCategoriesOptions = async () => {
    try {
      const response = await categoriesOptions(token())
      await getStixOptions(response.list)
    } catch (err) {}
  }

  const getStixOptions = async (categoriesData) => {
    try {
      const response = await stixOptions(token())
      const categories = {}
      response.list.some((item) => {
        if (!categories[item.category]) {
          categories[item.category] = []
        }
      })

      response.list.forEach((item) => {
        categories[item.category] = [...categories[item.category], item]
      })

      setTagsOptions({ categories: categoriesData, stix: categories, tags: [] })
      isCreateNew ? setLoading(false) : await getTagDetails()
    } catch (err) {}
  }

  const getTagDetails = async () => {
    try {
      const response = await singleTag(token(), 'tags', id)
      setData(response.data)
      setLoading(false)
    } catch (err) {}
  }

  const getTagOptions = debounce(async (query) => {
    cancelRequests !== undefined && cancelRequests()
    try {
      const response = await tagsList(token(), inputValue)
      response.list && setTagsOptions({ ...tagsOptions, tags: response.list })
    } catch (err) {}
  }, 300)

  useEffect(() => {
    inputValue === ''
      ? setTagsOptions({ ...tagsOptions, tags: [] })
      : getTagOptions(inputValue)
  }, [inputValue])

  const submitTagData = async () => {
    try {
      let response = ''
      if (id === 'new') {
        response = await addTag(token(), data)
      } else {
        response = await editTag(token(), id, data)
      }

      if (response.error) {
        errorToast({ message: response.message })
        setLoading(false)
        return false
      }

      successToast({ message: response.message })
      setLoading(false)

      if (id === 'new') {
        history.push('/app/admin/system-settings/tags/tags')
      }
    } catch {}
  }

  useEffect(() => {
    ;(async () => {
      const ignore = await getCategoriesOptions()
    })()
  }, [])

  const handleState = (prop, value) => {
    const currentData = { ...data }

    currentData[prop] = value
    setData(currentData)
  }

  return isLoading ? (
    <Spinner centerHorizontally padding={20} size={30} border={1} />
  ) : (
    <div className='edit-admin'>
      <h2 style={{ marginBottom: 10 }}>Edit Tag</h2>

      <div className='edit-admin__form'>
        <div style={{ width: '100%', margin: '6px 0' }}>
          <h5 style={{ marginBottom: 10 }}>Name</h5>

          <TextField
            fullWidth
            variant='outlined'
            placeholder='Name'
            value={data.name || ''}
            required
            onChange={(event) => handleState('name', event.target.value)}
          />

          <h5 style={{ margin: '10px 0' }}>Categories</h5>

          <Autocomplete
            multiple
            disableClearable
            filterSelectedOptions
            // groupBy={option => option.app}
            getOptionSelected={(option, value) => option.id === value}
            options={tagsOptions.categories || []}
            value={data.categories || []}
            getOptionLabel={(option) => option.name}
            renderTags={(tagValue, getTagProps) => {}}
            onChange={(event, newValue) => {
              if (event.keyCode === 8) return false

              const value = newValue[newValue.length - 1]
              const newState = [...data.categories, value.id]

              handleState('categories', newState)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder='Search tags'
                variant='outlined'
                className='tag-input'
                size='small'
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? <CircularProgress size={20} /> : null}

                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </div>

        {data.categories && (
          <div style={{ marginTop: 10 }}>
            {data.categories.map((option) => (
              <Chip
                key={option}
                onDelete={() =>
                  handleState(
                    'categories',
                    data.categories.filter((cat) => cat !== option),
                  )
                }
                style={{
                  height: 28,
                  fontSize: 11,
                  marginRight: 4,
                  backgroundColor: '#ffc9c9',
                }}
                label={
                  tagsOptions?.categories?.find((cat) => cat.id === option)
                    ?.name
                }
                deleteIcon={
                  <span
                    className='icon-close'
                    style={{
                      width: 'unset',
                      height: 'unset',
                      fontSize: 14,
                      padding: '4px 4px 4px 0',
                      color: '#000',
                    }}
                  />
                }
              />
            ))}
          </div>
        )}

        <h5 style={{ margin: '10px 0' }}>Assign other tags</h5>

        <Autocomplete
          multiple
          disableClearable
          filterSelectedOptions
          // groupBy={option => option.app}
          getOptionSelected={(option, value) => option.id === value}
          options={[...tagsOptions.tags, ...data.tagsData]}
          value={data.tags || []}
          getOptionLabel={(option) => option.name}
          renderTags={(tagValue, getTagProps) => {}}
          onChange={(event, value) => {
            const currentState = { ...data }
            const newValue = value[value.length - 1]
            const newTag = {
              id: newValue.id,
              text: newValue.name,
              pivot: { sector_id: id * 1, tag_id: newValue.id },
            }
            currentState.tagsData = [...currentState.tagsData, newTag]
            currentState.tags = [...currentState.tags, newTag.id]
            setData(currentState)
          }}
          inputValue={inputValue}
          onInputChange={(event, value, reason) => {
            if (event && event.type === 'blur') {
              setInputValue('') //inputValue);
            } else if (reason !== 'reset') {
              setInputValue(value)
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder='Search tags'
              variant='outlined'
              className='tag-input'
              size='small'
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress size={20} /> : null}

                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />

        <div style={{ marginTop: 10 }}>
          {data.tagsData.map((piece) => (
            <Chip
              style={{
                backgroundColor: '#FFF4C9',
                color: '#000',
                margin: 2,
                fontSize: 10,
                height: 28,
              }}
              onClick={() => {}}
              onDelete={() => {
                const currentState = { ...data }
                currentState.tagsData = currentState.tagsData.filter(
                  (tag) => tag.id !== piece.id,
                )
                currentState.tags = currentState.tags.filter(
                  (tag) => tag !== piece.id,
                )
                setData(currentState)
              }}
              label={piece.text}
              key={piece.id}
              deleteIcon={
                <span
                  className='icon-close'
                  style={{
                    width: 'unset',
                    height: 'unset',
                    fontSize: 14,
                    padding: '4px 4px 4px 0',
                    color: '#000',
                  }}
                />
              }
            />
          ))}
        </div>

        <h5 style={{ margin: '10px 0' }}>Stix vocabulary</h5>

        <Select
          variant='outlined'
          displayEmpty
          renderValue={
            !data.stix_type || data.stix_type === ''
              ? () => <span style={{ color: '#aaa' }}>Select Stix</span>
              : null
          }
          onChange={(event) => handleState('stix_type', event.target.value)}
          MenuProps={{ style: { maxHeight: 400 } }}
          value={data.stix_type || ''}
          onClose={() => setStixMenu(null)}
        >
          {Object.entries(tagsOptions.stix).map((item, i) => {
            const categoryName = item[0]
            const categoryItems = item[1]

            return [
              // eslint-disable-next-line react/jsx-key
              <ListSubheader>{categoryName}</ListSubheader>,

              categoryItems.map(({ name }) => (
                <MenuItem style={{ fontSize: 14 }} key={name} value={name}>
                  {name}
                </MenuItem>
              )),
            ]
          })}
        </Select>
      </div>

      <div style={{ marginTop: 20 }}>
        <Button onClick={() => submitTagData()} className='create-new'>
          {id === 'new' ? 'Add New Tag' : 'Edit Tag'}
        </Button>

        <Button
          style={{
            textTransform: 'capitalize',
            marginLeft: 20,
            borderRadius: 12,
            padding: '6px 16px',
          }}
          onClick={() => history.push('/app/admin/system-settings/tags/tags')}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default EditTag
