import { Chip } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import React from 'react'
import './TagsTab.scss'
import PropTypes from 'prop-types'
import { useGlobalContext } from '../../../context/globalContext'

/**
 * TagsTab
 * @param {object} data - list of items to map
 */

const TagsTab = ({ data }) => {
  const history = useHistory()
  const { setGlobalSearchFilters } = useGlobalContext()

  return (
    <div className='tags-tab'>
      {data?.map((tag, index) => (
        <Chip
          onClick={() => {
            const tagName = tag.mitre_technique_id
              ? tag.name.split(' - ')[0]
              : tag.name
            setGlobalSearchFilters({ fromDashboard: true, keywords: tagName })
            history.push('/app/search')
          }}
          label={tag.name}
          style={{
            backgroundColor: tag.mitre_technique_id ? '#35415A' : '#8D9CB8',
            margin: 4,
            color: '#fff',
            fontSize: 10,
            transition: 'all 0s',
          }}
          key={index}
        />
      ))}
    </div>
  )
}

TagsTab.displayName = 'TagsTab'

/**
 * The properties.
 * @type {Object}
 */

TagsTab.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default TagsTab
