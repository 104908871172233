/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { Button, Dialog, Divider } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { formatDate } from '../../../shared/helpers/formatDate'
import { reportedResourceThread } from '../../../shared/services/api/moderation/moderation'
import Spinner from '../../../shared/Spinner'
import ModerationSub from './ModerationSub'
import OptionsMenu from './OptionsMenu'

const ModerationModal = ({
  commentModal,
  setCommentModal,
  handleChangeStatus,
  type,
  commentData,
}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [comment, setComment] = useState(null)
  const [hoveredId, setHoveredId] = useState({})
  const [menuHoverAnchor, setMenuHoverAnchor] = useState(null)
  const [isLoading, setLoading] = useState(!commentData)

  const fetchReportedCommentThread = async (type) => {
    try {
      const response = await reportedResourceThread(token(), commentModal, type)
      setComment(
        response?.data?.comment?.parent ||
          response?.data?.comment ||
          response.message,
      )
      setLoading(false)
    } catch (err) {}
  }

  useEffect(() => {
    commentData ? setComment(commentData) : fetchReportedCommentThread(type)
  }, [type])

  return (
    <Dialog
      open={commentModal !== null}
      fullWidth
      className='moderation-modal'
      maxWidth='lg'
    >
      <div style={{ padding: 20 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          {commentData ? (
            <span>Thread title: {commentData?.thread?.title}</span>
          ) : (
            <span>
              Content title:{' '}
              {comment?.commentable_item?.title ?? comment?.content}
            </span>
          )}

          <span>
            Author:{' '}
            {comment?.commentable_item?.author?.name ??
              comment?.author?.display_name}
          </span>

          {comment?.commentable_item_type && (
            <span>
              Module: {comment?.commentable_item_type?.split('\\')?.pop()}
            </span>
          )}
        </div>

        <Divider style={{ margin: '15px 0' }} />

        {isLoading && <Spinner centerHorizontally padding={30} />}

        {!isLoading && comment && (
          <div
            onMouseOver={() => setHoveredId(comment.id)}
            onMouseLeave={() => setHoveredId(null)}
            className='moderation-modal__comment'
            style={{
              borderLeft: comment.is_reported ? '5px solid #ff0033' : null,
            }}
          >
            <div className='moderation-modal__comment-content'>
              <div
                style={{
                  fontSize: '14px',
                  color:
                    comment.is_reported || comment.is_deleted
                      ? '#7f7f7f'
                      : '#283143',
                  fontStyle:
                    comment.is_reported || comment.is_deleted
                      ? 'italic'
                      : 'unset',
                }}
                dangerouslySetInnerHTML={{ __html: comment.content }}
              />

              <div style={{ fontSize: '11px', color: '#7f7f7f' }}>
                {`${
                  comment.author_name ?? comment?.author?.display_name
                } - ${formatDate(comment.created_at)}`}
              </div>
            </div>

            {comment.id === hoveredId && comment.is_reported && (
              <OptionsMenu
                setMenuHoverAnchor={setMenuHoverAnchor}
                menuHoverAnchor={menuHoverAnchor}
                comment={comment}
                handleChangeStatus={handleChangeStatus}
                id={commentModal}
                type={type}
              />
            )}
          </div>
        )}

        {comment?.subcomments?.map((sub) => (
          <ModerationSub
            setMenuHoverAnchor={setMenuHoverAnchor}
            menuHoverAnchor={menuHoverAnchor}
            sub={sub}
            hoveredId={hoveredId}
            setHoveredId={setHoveredId}
            key={sub.id}
            handleChangeStatus={handleChangeStatus}
            commentModal={commentModal}
          />
        ))}

        <Divider style={{ margin: '10px 0' }} />

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            style={{
              textTransform: 'capitalize',
              borderRadius: 12,
              padding: '4px 14px',
            }}
            onClick={() => setCommentModal(null)}
          >
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default ModerationModal
