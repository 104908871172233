import { Button, IconButton, Tooltip } from '@material-ui/core'
import ConfirmDelete from '../../ContentManage/ConfirmDelete'
import ModerationModal from '../ModerationModal'
import { Delete } from '@mui/icons-material'
import { useReportedResource } from './useReportedResource'

const commentsStatuses = [
  { name: 'Unactioned', slug: 'unactioned', render: true },
  { name: 'In progress', slug: 'in-progress', render: true },
  { name: 'Actioned', slug: 'actioned', render: true },
  { name: 'Comment shown', slug: 'shown' },
  { name: 'Comment hidden', slug: 'hidden' },
]

export const ReportedForumContent = ({ type }) => {
  const {
    data,
    data: messages,
    handleDeleteReportedResource,
    handleChangeStatus,
    resourceModal,
    setResourceModal,
    deleteModal,
    setDeleteModal,
    isDeleting,
  } = useReportedResource(type)

  return (
    <div className='moderation-container'>
      <div className='moderation-container__header'>
        <h3>Reported messages</h3>

        <div className='moderation-statuses'>
          {commentsStatuses
            .filter((stat) => stat.render)
            .map(({ name, slug }) => (
              <div key={slug} className={`status-${slug}`}>
                <span>{name}</span>
              </div>
            ))}
        </div>
      </div>

      {deleteModal && (
        <ConfirmDelete
          confirmDelete={deleteModal}
          setConfirmDelete={setDeleteModal}
          handleDeletePiece={handleDeleteReportedResource}
          isDeleting={isDeleting}
          typeOfReportedContent={type}
        />
      )}

      {resourceModal && (
        <ModerationModal
          commentModal={resourceModal}
          setCommentModal={setResourceModal}
          handleChangeStatus={handleChangeStatus}
          type={type}
          commentData={data?.find((i) => i?.id === resourceModal)?.item}
        />
      )}

      <div className='moderation-list'>
        {messages?.map(
          ({
            status,
            id,
            reported,
            reason,
            message,
            resolver,
            item,
            group_name,
          }) => (
            <div
              key={id}
              className={`comment comment-${
                commentsStatuses?.find((i) => i.name === status)?.slug
              }`}
            >
              <div className='moderation-list__details'>
                <span>Reported by: {reported.display_name}</span>

                <span>Report reason: {reason}</span>

                <span>
                  Source: group name: <b>{group_name}</b>, thread title:{' '}
                  <b>{item?.thread?.title}</b>
                </span>

                <span>Post author: {item?.author?.display_name}</span>

                {resolver && (
                  <span>
                    Resolver: <b>{resolver.username}</b>
                  </span>
                )}
              </div>

              <div className='moderation-list__content'>
                <div
                  style={{ fontStyle: 'italic' }}
                  dangerouslySetInnerHTML={{ __html: message?.content }}
                />
              </div>

              <div className='moderation-list__actions'>
                <div
                  style={{
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 10,
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: '#006fff',
                      color: '#fff',
                      textTransform: 'capitalize',
                      borderRadius: 12,
                      padding: '5px 14px',
                    }}
                    onClick={() => {
                      setResourceModal(id)
                      handleChangeStatus(id, 'In progress', type)
                    }}
                  >
                    View Thread
                  </Button>

                  {status !== 'Content  shown' && (
                    <Button
                      style={{
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        color: '#fff',
                        textTransform: 'capitalize',
                        borderRadius: 12,
                        padding: '5px 14px',
                      }}
                      onClick={() =>
                        handleChangeStatus(id, 'Content shown', type)
                      }
                    >
                      Restore
                    </Button>
                  )}

                  {status !== 'Content hidden' && (
                    <Button
                      style={{
                        backgroundColor: '#ff0033',
                        color: '#fff',
                        textTransform: 'capitalize',
                        borderRadius: 12,
                        padding: '5px 14px',
                      }}
                      onClick={() =>
                        handleChangeStatus(id, 'Content hidden', type)
                      }
                    >
                      Delete
                    </Button>
                  )}

                  {(status === 'Content shown' ||
                    status === 'Content hidden') && (
                    <Tooltip title='Resolve and remove entry' placement='top'>
                      <IconButton
                        style={{ padding: 6 }}
                        onClick={() => setDeleteModal(id)}
                      >
                        <Delete style={{ color: '#ff0033', fontSize: 22.5 }} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {/* 
                  <Tooltip arrow placement='top' title='Open in new workspace'>
                    <IconButton
                      style={{ padding: 6, color: '#fff' }}
                      onClick={() => {
                        const {
                          commentable_item_type,
                          commentable_item_id,
                          commentable_item,
                        } = comment
                        const module = commentable_item_type
                          .split('\\')
                          .pop()
                          .toLowerCase()
                        const contentModule =
                          module === 'profile'
                            ? commentable_item.type.slug
                            : module

                        addWorkspace(
                          contentModule,
                          commentable_item_id,
                          commentable_item.title,
                          true,
                        )
                      }}
                    >
                      <OpenInNew style={{ fontSize: 22.5 }} />
                    </IconButton>
                  </Tooltip> */}
                </div>

                <div>
                  <span style={{ fontSize: 13 }}>
                    Current status: <b>{status}</b>
                  </span>
                </div>
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  )
}
