import React from 'react'
import MuiDatePicker from './MuiDatePicker'

const DateRangePickerComponent = ({
  selectedDateRange,
  handleFilters,
  isContentManagement,
}) => {
  const isValidDate = (d) => d instanceof Date && !isNaN(d)
  const onDateRangeChange = (dateInput, value) => {
    let currentDateRange = [...selectedDateRange]

    if (dateInput === 'start') {
      currentDateRange = currentDateRange[1]
        ? [value, currentDateRange[1]]
        : [value] //updating start date
    } else {
      currentDateRange = currentDateRange[0]
        ? [currentDateRange[0], value]
        : [value, value] //updating end date
    }

    if (currentDateRange[0] && isValidDate(currentDateRange[0])) {
      //if only a single date exists
      handleFilters(
        isContentManagement ? 'date_range' : 'dateRange',
        currentDateRange,
      )
    } else if (
      currentDateRange[1] &&
      isValidDate(currentDateRange[0]) &&
      isValidDate(currentDateRange[1])
    ) {
      //if two dates exist
      handleFilters(
        isContentManagement ? 'date_range' : 'dateRange',
        currentDateRange,
      )
    } else {
      //if any of the dates is invalid
      handleFilters(isContentManagement ? 'date_range' : 'dateRange', [])
    }
  }

  return (
    <div>
      <div>
        <h4 style={{ color: 'white', marginTop: 16, marginBottom: -8 }}>
          Start date:
        </h4>

        <MuiDatePicker
          enableFutureDate={false}
          initialDate={selectedDateRange[0]}
          maxDate={selectedDateRange[1]}
          value={selectedDateRange[0]}
          onDateRangeChange={onDateRangeChange}
          dateLabel='start'
        />
      </div>

      <div>
        <h4 style={{ color: 'white', marginTop: 16, marginBottom: -8 }}>
          End date:
        </h4>

        <MuiDatePicker
          initialDate={selectedDateRange[1]}
          minDate={selectedDateRange[0]}
          enableFutureDate
          enablePastDate
          onDateRangeChange={onDateRangeChange}
          dateLabel='end'
        />
      </div>
    </div>
  )
}

export default DateRangePickerComponent
