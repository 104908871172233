import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import Spinner from '../../../../shared/Spinner'
import { useHistory, useParams } from 'react-router-dom'
import { TextField } from '@mui/material'
import {
  singleTag,
  editTagCategory,
  addTagCategory,
} from '../../../../shared/services/api/admin/settings/tags'
import './EditTag.scss'
import useNotification from '../../../../hooks/useNotification'

const EditTagCategory = ({}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const initialData = { name: '', icon: null, tags: [] }
  const history = useHistory()
  const { id } = useParams()
  const isCreateNew = id === 'new'
  const [isLoading, setLoading] = useState(!isCreateNew)
  const [data, setData] = useState(isCreateNew ? initialData : {})
  const [isSaving, setSaving] = useState(false)
  const { successToast, errorToast } = useNotification()

  const getTagCategoryDetails = async () => {
    try {
      const response = await singleTag(token(), 'tag-categories', id)
      setData(response.data)
      setLoading(false)
    } catch (err) {}
  }

  const submitTagData = async () => {
    setSaving(true)
    try {
      let response = ''
      if (id === 'new') {
        response = await addTagCategory(token(), data)
      } else {
        response = await editTagCategory(token(), id, data)
      }

      if (response.error) {
        errorToast({ message: response.message })
        setLoading(false)
        setSaving(false)
        return false
      }

      successToast({ message: response.message })
      setLoading(false)
      setSaving(false)
      isCreateNew &&
        history.push('/app/admin/system-settings/tags/tag-categories')
    } catch {}
  }

  useEffect(() => {
    ;(async () => {
      !isCreateNew && (await getTagCategoryDetails())
    })()
  }, [isCreateNew])

  const handleState = (prop, value) => {
    const currentData = { ...data }

    currentData[prop] = value
    setData(currentData)
  }

  return isLoading ? (
    <Spinner centerHorizontally padding={20} size={30} />
  ) : (
    <div className='edit-admin'>
      <h2 style={{ marginBottom: 10 }}>
        {isCreateNew ? 'Create New Tag Category' : 'Edit Tag Category'}
      </h2>

      <div className='edit-admin__form'>
        <div style={{ width: '100%', margin: '6px 0' }}>
          <h5 style={{ marginBottom: 5 }}>Name</h5>

          <TextField
            fullWidth
            variant='outlined'
            placeholder='Name'
            value={data.name || ''}
            required
            onChange={(event) => handleState('name', event.target.value)}
          />
        </div>
      </div>

      <div style={{ marginTop: 20 }}>
        <Button
          disabled={isSaving}
          onClick={() => submitTagData()}
          className='create-new'
        >
          {isSaving ? (
            <Spinner size={22.5} centerHorizontally color='#fff' />
          ) : id === 'new' ? (
            'Add New Tag Category'
          ) : (
            'Edit Tag Category'
          )}
        </Button>

        <Button
          style={{
            textTransform: 'capitalize',
            marginLeft: 20,
            borderRadius: 12,
            padding: '6px 16px',
          }}
          onClick={() =>
            history.push('/app/admin/system-settings/tags/tag-categories')
          }
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default EditTagCategory
