import { useEffect, useState } from 'react'
import useNotification from '../../../hooks/useNotification'
import {
  deleteReportedResource,
  getReportedResource,
  reportedResourceStatus,
} from '../../../shared/services/api/moderation/moderation'

export const useReportedResource = (type) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [data, setData] = useState([])
  const [resourceModal, setResourceModal] = useState(null)
  const [deleteModal, setDeleteModal] = useState(undefined)
  const { successToast, errorToast } = useNotification()
  const [isDeleting, setDeleting] = useState(false)

  const fetchReportedResource = async () => {
    try {
      const response = await getReportedResource(token(), type)
      setData(response?.list)
    } catch (err) {}
  }

  const handleDeleteReportedResource = async (id, type) => {
    setDeleting(true)
    try {
      await deleteReportedResource(token(), id, type)
      await fetchReportedResource()
      setDeleting(false)
      setDeleteModal(undefined)
    } catch {}
  }

  useEffect(() => {
    fetchReportedResource()
  }, [])

  const handleChangeStatus = async (id, status, type) => {
    try {
      const { message, error } = await reportedResourceStatus(
        token(),
        id,
        { status },
        type,
      )
      const currentData = [...data]

      currentData.find((resource) => resource.id === id).status = status
      setData(currentData)
      error ? errorToast({ message }) : successToast({ message })
    } catch (err) {}
  }

  return {
    data,
    handleDeleteReportedResource,
    handleChangeStatus,
    resourceModal,
    setResourceModal,
    deleteModal,
    setDeleteModal,
    isDeleting,
    setDeleting,
  }
}
