/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import {
  Button,
  IconButton,
  ClickAwayListener,
  Tooltip,
} from '@material-ui/core'
import { Menu, QuestionAnswer } from '@mui/icons-material'

import './TopBar.scss'
import UserSettings from './UserSettings'
import UserLayout from './layouts/UserLayout'
import AdminLayout from './layouts/AdminLayout'
import WorkspaceTabs from '../WorkspaceTabs'
import { ReactComponent as Logo } from '../../assets/tm-logo-white.svg'
import { useUserContext } from '../../context/userContext'
import { useViewStateContext } from '../../context/viewStateContext'
import { apiUrl } from '../../shared/config'
import { useChat } from '../../context/chatContext'

/**
 * TopBar
 */

const TopBar = () => {
  const history = useHistory()
  const [menuOpen, setMenuOpen] = useState(null)
  const { chatOpen, setChatOpen } = useChat()
  const { workspaces, addWorkspace, setWorkspaces } = useViewStateContext()

  const {
    leftMenuVisible,
    setLeftMenuVisible,
    user: {
      is_admin,
      is_chat_enabled,
      user_info: { first_name, last_name, avatar },
    },
  } = useUserContext()
  const token = () => {
    return localStorage.getItem('access_token')
  }

  const handleNavigateTo = (route) => history.push(`/app/${route}`)
  const handleTabChange = (item) => {
    is_admin
      ? history.push(`/app/admin/settings/${item}`)
      : history.push(`/app/settings/${item}`)
    setMenuOpen(null)
  }

  return (
    <div className='topbar'>
      <div className='topbar__logo'>
        {isMobile && (
          <IconButton onClick={() => setLeftMenuVisible(!leftMenuVisible)}>
            <Menu style={{ color: '#fff' }} />
          </IconButton>
        )}

        <Logo
          style={{ width: isMobile ? 40 : 30, height: isMobile ? 40 : 30 }}
          onClick={() => {
            if (is_admin) {
              handleNavigateTo('admin')
            } else {
              setWorkspaces((currentState) => {
                const newState = [...currentState]
                newState?.forEach((w) => (w.active = false))
                newState[0].active = true
                return newState
              })
              handleNavigateTo('workspace')
            }
          }}
        />
      </div>

      <div className='topbar__container'>
        {!isMobile && (
          <div
            style={{ paddingTop: is_admin ? '0' : '12px' }}
            className='topbar__tabs'
          >
            <AdminLayout />

            {!is_admin && workspaces?.length && (
              <div className='workspace-tabs'>
                {workspaces.map((item) => (
                  <WorkspaceTabs key={item.id} item={item} />
                ))}

                {workspaces.length < 16 && (
                  <IconButton
                    className='add-workspace'
                    // style={{ pointerEvents: preventAdd ? 'none' : 'all' }}
                    onClick={() => addWorkspace('')}
                    color='inherit'
                  >
                    <span className='icon-circle-add' />
                  </IconButton>
                )}
              </div>
            )}
          </div>
        )}

        <div className='topbar__right'>
          <UserLayout />

          <div className='sign-out'>
            <Button
              variant='contained'
              onClick={(event) => {
                const isButton = !!event?.target?.className?.length
                isButton && setMenuOpen(!menuOpen)
              }}
              startIcon={
                avatar ? (
                  <Tooltip
                    placement='bottom'
                    title='Click to change avatar'
                    arrow
                  >
                    <div
                      style={{ height: 44, width: 44 }}
                      onClick={() => history.push('/app/settings/user-details')}
                    >
                      <img
                        src={`${apiUrl}/storage/module/user/avatar/${avatar}?access_token=${token()}`}
                        alt={avatar}
                        style={{
                          width: 44,
                          height: 44,
                          objectFit: 'cover',
                          borderRadius: 20,
                        }}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <span
                    className='icon-user'
                    style={{ fontSize: isMobile ? 26 : 20 }}
                  />
                )
              }
              endIcon={
                <span
                  style={{ transform: menuOpen ? 'rotate(180deg)' : '' }}
                  className='icon-down'
                />
              }
            >
              {!isMobile && <span className='user-data'>{first_name}</span>}

              {!isMobile && <span className='user-data'>{last_name}</span>}
            </Button>

            {menuOpen && (
              <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                <div className='menu'>
                  <UserSettings
                    isAdmin={is_admin}
                    handleTabChange={handleTabChange}
                  />
                </div>
              </ClickAwayListener>
            )}
          </div>

          {is_chat_enabled ? (
            <IconButton
              className={chatOpen ? 'create active' : 'create'}
              disableRipple
              onClick={() => setChatOpen((current) => !current)}
              size='medium'
              style={{ width: chatOpen ? 100 : 76.5 }}
            >
              <QuestionAnswer />
            </IconButton>
          ) : null}
        </div>
      </div>
    </div>
  )
}

TopBar.displayName = 'TopBar'

/**
 * The properties.
 * @type {Object}
 */

TopBar.propTypes = {}

export default TopBar
