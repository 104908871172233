import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const getDashboard = (token) =>
  client.get(apiUrl + '/api/dashboard-v2', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
